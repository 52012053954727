<template>
  <div class="py-2 px-3 max-w-sm flex items-center h-full relative overflow-hidden">
    <div class="flex flex-col gap-1" v-if="rewards.current">
      <div class="text-slate-900 font-medium leading-tight line-clamp-1">
        {{ rewards.current.title }}
      </div>
      <div class="flex flex-row gap-1.5">
        <div class="relative h-6 w-6 flex-grow-0 flex-shrink-0 basis-6">
          <!-- https://github.com/vuejs/core/issues/6088 -->
          <!-- prettier-ignore -->
          <component
            v-for="{ icon, color: backgroundColor }, i in [
              rewards.current,
              ...rewards.earned,
            ]"
            :key="icon"
            :is="icons[icon]"
            :style="{
              backgroundColor,
              left: `-${i * 4}px`,
              zIndex: rewards.earned.length - i,
            }"
            class="absolute h-6 stroke-white p-1 rounded-full transition-[left]"
          />
        </div>
        <div class="flex-grow">
          <div class="overflow-hidden rounded-full bg-slate-200 h-2 relative">
            <div
              v-for="{ size: width, color: backgroundColor } in rewards.progressBar"
              class="h-2 rounded-full absolute transition-[width] duration-1000 ease-in-out delay-75"
              :style="{ width: `${width}%`, backgroundColor }"
            />
          </div>
          <div class="text-slate-600 text-xs leading-tight tracking-tight pt-1 line-clamp-1">
            Faltam {{ currencyFormat(rewards.valueForNextReward) }} pra ganhar
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="!rewards.current && rewards.earned.length" class="leading-tight font-medium">
      Ganhou entrega em casa grátis
    </div>

    <a
      href="https://mercado.mara.com.br/clube"
      class="absolute inset-0 py-2 px-3 bg-white z-30 duration-500 transition-[top]"
      :style="{ top: showClubOverlay ? '0px' : '80px' }"
      v-if="!me?.club_member"
    >
      <div class="font-medium">Faça parte do Clube Mara</div>
      <div class="text-sm text-slate-500 break-keep whitespace-nowrap overflow-hidden">
        <div class="scroll-text">
          👑 Preços ainda menores 👑 Produtos exclusivos 👑 Entrega grátis em casa a partir de R$149
          👑 Cashback em todas as compras
        </div>
      </div>
    </a>
  </div>
</template>

<style>
.scroll-text {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-350%);
  }
}

@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-350%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-350%);
    -webkit-transform: translateX(-350%);
    transform: translateX(-350%);
  }
}
</style>

<script lang="ts" setup>
import { StarIcon, TruckIcon, CurrencyDollarIcon } from '@heroicons/vue/outline'
import { useStore } from '@nanostores/vue'
import { ref, watch } from 'vue'
import { rewards as rewardsStore, $showBonusValue } from '../services/rewards'
import { currencyFormat } from '../services/format'
import { cart } from '../services/cart'
import { $me } from '../services/me'

const enabled = ref($showBonusValue.get())

watch(enabled, (value) => {
  $showBonusValue.set(value)
})

const icons = ref({
  star: StarIcon,
  truck: TruckIcon,
  dollar: CurrencyDollarIcon,
})

const rewards = useStore(rewardsStore)
const me = useStore($me)
const showClubOverlay = ref(false)

let timeout: NodeJS.Timeout

function hideBonus() {
  showClubOverlay.value = false

  if (timeout) {
    clearTimeout(timeout)
  }

  timeout = setTimeout(() => {
    showClubOverlay.value = true
  }, 3000)
}

function onMe(value: { slug?: string; enabled_for_bonus?: boolean } | null) {
  if (value?.enabled_for_bonus) {
    hideBonus()
  }
}

if (me.value) {
  onMe(me.value)
}

watch(me, (value) => {
  onMe(value)
})

cart.listen(hideBonus)
</script>
